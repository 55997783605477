<template>
  <div class="dashboard-container">
    <div>
      <el-tabs v-model="type" @tab-click="handleClick">
        <el-tab-pane label="拖车成本" name="1">
          <TrailerCost ref="tableOne" :option-list="optionList" />
          <!-- <zero-load-cost :optionList="optionList"></zero-load-cost> -->
        </el-tab-pane>
        <el-tab-pane label="拖车服务" name="2">
          <VehicleTowing ref="tableTwo" :option-list="optionList" />
          <!-- <urban-distribution-cost></urban-distribution-cost> -->
          <!-- <zero-load-service :optionList="optionList"></zero-load-service> -->
        </el-tab-pane>
        <!-- <el-tab-pane label="拖车询价总览表" name="3">
          <trailer-report :optionList="optionList" ref="tab3"></trailer-report>
        </el-tab-pane> -->
      </el-tabs>
    </div>
    <!-- <div v-if="type == '1'">
      <inquiry-list :optionList="optionList"></inquiry-list>
    </div>
    <div v-if="type == '2'">
      <bulk-cargo-report :optionList="optionList"></bulk-cargo-report>
    </div>
    <div v-if="type == '3'">
        <trailer-report :optionList="optionList"></trailer-report>
    </div> -->
  </div>
</template>
<script>
  import TrailerCost from './components/TrailerCost.vue'
  import VehicleTowing from './components/VehicleTowing.vue'
  import {
    productCommissionerGetValidList,
    baseSupplierGetValid,
    portGetValidList,
  } from '@/api/inquiryManagement'
  export default {
    name: 'TrailerProducts',
    components: { TrailerCost, VehicleTowing },
    data() {
      return {
        type: '1',
        optionList: {
          productCommissionerGetValidList: [], //产品专员
          baseSupplierGetValidList: [], //供应商
          portGetValidList: [], //港口
          taxList: [
            {
              id: 1,
              name: '是',
            },
            {
              id: 0,
              name: '否',
            },
          ], //是否含税
        },
      }
    },
    // mounted() {
    //   this.selectList()
    //   this.queryList()
    // },
    activated() {
      this.selectList()
      this.queryList()
    },
    methods: {
      queryList() {
        this.$nextTick(() => {
          if (this.type == '1') {
            this.$refs['tableOne'].queryList()
          } else {
            this.$refs['tableTwo'].queryList()
          }
        })
      },
      handleClick() {
        this.queryList()
      },
      async selectList() {
        Promise.all(
          [
            productCommissionerGetValidList({}),
            baseSupplierGetValid({}),
            portGetValidList({}),
          ].map((v) => v.catch((e) => console.log(e)))
        ).then((res) => {
          this.optionList.productCommissionerGetValidList = res[0].data
          this.optionList.baseSupplierGetValidList = res[1].data
          this.optionList.portGetValidList = res[2].data
        })

        // const res = await productCommissionerGetValidList({})
        // this.optionList.productCommissionerGetValidList = res.data
        // const req = await baseSupplierGetValid({})
        // this.optionList.baseSupplierGetValidList = req.data
        // const rew = await portGetValidList({})
        // this.optionList.portGetValidList = rew.data
      },
    },
  }
</script>
<style lang="scss">
  .el-tooltip__popper {
    max-width: 20%;
  }
</style>
