var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-top-panel",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    "label-width": "70px",
                    inline: true,
                    model: _vm.form
                  },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "供应商" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            multiple: "",
                            filterable: "",
                            clearable: "",
                            "collapse-tags": "",
                            placeholder: "请选择"
                          },
                          on: { change: _vm.handleBlur },
                          model: {
                            value: _vm.form.supplierIds,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "supplierIds", $$v)
                            },
                            expression: "form.supplierIds"
                          }
                        },
                        _vm._l(
                          _vm.optionList.baseSupplierGetValidList,
                          function(item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id }
                            })
                          }
                        ),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "提货地址" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "请输入提货地址" },
                        on: { change: _vm.handleBlur, blur: _vm.handleBlur },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleBlur.apply(null, arguments)
                          }
                        },
                        model: {
                          value: _vm.form.pick,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "pick", $$v)
                          },
                          expression: "form.pick"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "起运港" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "180px" },
                          attrs: {
                            filterable: "",
                            clearable: "",
                            placeholder: "请选择"
                          },
                          on: { change: _vm.handleBlur },
                          model: {
                            value: _vm.form.dispatch,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "dispatch", $$v)
                            },
                            expression: "form.dispatch"
                          }
                        },
                        _vm._l(_vm.optionList.portGetValidList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.name }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "创建时间" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "350px" },
                        attrs: {
                          type: "daterange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          "value-format": "yyyy-MM-dd",
                          "picker-options": _vm.pickerOptions,
                          "unlink-panels": ""
                        },
                        on: { change: _vm.pickerClick },
                        model: {
                          value: _vm.beginTime,
                          callback: function($$v) {
                            _vm.beginTime = $$v
                          },
                          expression: "beginTime"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.fold,
                          expression: "!fold"
                        }
                      ],
                      attrs: { label: "产品专员" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            clearable: "",
                            placeholder: "请选择"
                          },
                          on: { change: _vm.handleBlur },
                          model: {
                            value: _vm.form.productCommissionerId,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "productCommissionerId", $$v)
                            },
                            expression: "form.productCommissionerId"
                          }
                        },
                        _vm._l(
                          _vm.optionList.productCommissionerGetValidList,
                          function(item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id }
                            })
                          }
                        ),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.fold,
                          expression: "!fold"
                        }
                      ],
                      attrs: { label: "编号" }
                    },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "请输入编号" },
                        on: { change: _vm.handleBlur, blur: _vm.handleBlur },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleBlur.apply(null, arguments)
                          }
                        },
                        model: {
                          value: _vm.form.code,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "code", $$v)
                          },
                          expression: "form.code"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.fold,
                          expression: "!fold"
                        }
                      ],
                      attrs: { label: "失效时间" }
                    },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "350px" },
                        attrs: {
                          type: "daterange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          "value-format": "yyyy-MM-dd",
                          "picker-options": _vm.pickerOptionsOne,
                          "unlink-panels": ""
                        },
                        on: { change: _vm.pickerClickOne },
                        model: {
                          value: _vm.beginTimeOne,
                          callback: function($$v) {
                            _vm.beginTimeOne = $$v
                          },
                          expression: "beginTimeOne"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.fold,
                          expression: "!fold"
                        }
                      ],
                      attrs: { label: "状态" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100px" },
                          attrs: {
                            filterable: "",
                            clearable: "",
                            placeholder: "请选择"
                          },
                          on: { change: _vm.handleBlur },
                          model: {
                            value: _vm.form.statusId,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "statusId", $$v)
                            },
                            expression: "form.statusId"
                          }
                        },
                        _vm._l(_vm.optionListTwo, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: { click: _vm.handleFold }
                        },
                        [
                          _vm.fold
                            ? _c("span", [_vm._v("展开")])
                            : _c("span", [_vm._v("合并")]),
                          _c("vab-icon", {
                            staticClass: "vab-dropdown",
                            class: { "vab-dropdown-active": _vm.fold },
                            attrs: { icon: "arrow-up-s-line" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "vab-query-form-left-panel",
            { attrs: { span: 20 } },
            [
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: "导入",
                    visible: _vm.dialogVisible,
                    width: "500px",
                    "before-close": _vm.handleClose
                  },
                  on: {
                    "update:visible": function($event) {
                      _vm.dialogVisible = $event
                    }
                  }
                },
                [
                  _c(
                    "span",
                    [
                      _c(
                        "el-upload",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading,
                              expression: "loading"
                            }
                          ],
                          staticClass: "upload-demo",
                          attrs: {
                            drag: "",
                            action: _vm.importExectUrl(),
                            "http-request": _vm.importExect,
                            "with-credentials": "",
                            "show-file-list": false
                          }
                        },
                        [
                          _c("i", { staticClass: "el-icon-upload" }),
                          _c("div", { staticClass: "el-upload__text" }, [
                            _vm._v(" 将文件拖到此处，或 "),
                            _c("em", [_vm._v("点击上传")])
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer"
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.dialogVisible = false
                            }
                          }
                        },
                        [_vm._v("取 消")]
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-download",
                    size: "mini",
                    type: "primary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.baseyCode("", "add")
                    }
                  }
                },
                [_vm._v(" 下载模板 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-upload",
                    size: "mini",
                    type: "primary"
                  },
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = true
                    }
                  }
                },
                [_vm._v(" 导入 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-circle-check",
                    size: "mini",
                    type: "primary",
                    disabled:
                      !_vm.bulkOperations.idList.length > 0 && !_vm.isQueryAll
                  },
                  on: {
                    click: function($event) {
                      return _vm.handleAdd("", "commit")
                    }
                  }
                },
                [_vm._v(" 提交 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    type: "primary",
                    disabled:
                      !_vm.bulkOperations.idList.length > 0 && !_vm.isQueryAll
                  },
                  on: {
                    click: function($event) {
                      return _vm.handleAdd("", "invalid")
                    }
                  }
                },
                [
                  _c("vab-icon", { attrs: { icon: "forbid-2-line" } }),
                  _vm._v(" 失效 ")
                ],
                1
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    type: "primary",
                    disabled:
                      !_vm.bulkOperations.idList.length > 0 && !_vm.isQueryAll
                  },
                  on: {
                    click: function($event) {
                      return _vm.handleAdd("", "statusBack")
                    }
                  }
                },
                [
                  _c("vab-icon", { attrs: { icon: "logout-circle-line" } }),
                  _vm._v(" 状态回退 ")
                ],
                1
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-download",
                    size: "mini",
                    type: "primary",
                    loading: _vm.anLoading
                  },
                  on: { click: _vm.handExport }
                },
                [_vm._v(" 导出 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-delete",
                    type: "danger",
                    size: "mini",
                    disabled:
                      !_vm.bulkOperations.idList.length > 0 && !_vm.isQueryAll
                  },
                  on: {
                    click: function($event) {
                      return _vm.handleAdd("", "delete")
                    }
                  }
                },
                [_vm._v(" 删除 ")]
              )
            ],
            1
          ),
          _c(
            "vab-query-form-right-panel",
            { attrs: { span: 4 } },
            [
              _c("el-checkbox", {
                attrs: { label: "操作查询到的全部数据" },
                model: {
                  value: _vm.isQueryAll,
                  callback: function($$v) {
                    _vm.isQueryAll = $$v
                  },
                  expression: "isQueryAll"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { height: "calc(100vh - 330px)" } },
        [
          _c(
            "vxe-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              ref: "tableSort",
              attrs: {
                border: "",
                "show-overflow": "",
                height: "100%",
                "row-config": { isHover: true, isCurrent: true, height: 40 },
                data: _vm.list,
                "scroll-y": { enabled: true },
                "checkbox-config": { trigger: "row", range: true },
                "row-class-name": _vm.rowStyle,
                resizable: true
              },
              on: {
                "checkbox-change": _vm.selectAllEvent,
                "checkbox-all": _vm.selectAllEvent
              },
              scopedSlots: _vm._u([
                {
                  key: "empty",
                  fn: function() {
                    return [
                      _c("el-image", {
                        staticClass: "vab-data-empty",
                        attrs: {
                          src: require("@/assets/empty_images/data_empty.png")
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  fixed: "left",
                  type: "checkbox",
                  width: "55"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "supplier",
                  title: "供应商",
                  width: "100"
                }
              }),
              _c(
                "vxe-colgroup",
                { attrs: { title: "提货地址", align: "center" } },
                [
                  _c("vxe-column", {
                    attrs: {
                      field: "pickCityName",
                      align: "center",
                      title: "起运市",
                      width: "80"
                    }
                  }),
                  _c("vxe-column", {
                    attrs: {
                      field: "pickAreasName",
                      align: "center",
                      title: "起运区",
                      width: "80"
                    }
                  }),
                  _c("vxe-column", {
                    attrs: {
                      field: "pickStreetName",
                      align: "center",
                      title: "起运街道",
                      width: "100"
                    }
                  })
                ],
                1
              ),
              _c(
                "vxe-colgroup",
                { attrs: { title: "送货地址", align: "center" } },
                [
                  _c("vxe-column", {
                    attrs: {
                      field: "dispatchPortName",
                      align: "center",
                      title: "起运港",
                      width: "80"
                    }
                  })
                ],
                1
              ),
              _c(
                "vxe-colgroup",
                { attrs: { title: "成本价", align: "center" } },
                [
                  _c("vxe-column", {
                    attrs: {
                      field: "cost20gp",
                      align: "center",
                      title: "20GP",
                      width: "70"
                    }
                  }),
                  _c("vxe-column", {
                    attrs: {
                      field: "cost40gp",
                      align: "center",
                      title: "40GP",
                      width: "70"
                    }
                  }),
                  _c("vxe-column", {
                    attrs: {
                      field: "cost40hq",
                      align: "center",
                      title: "40HQ",
                      width: "70"
                    }
                  }),
                  _c("vxe-column", {
                    attrs: {
                      field: "cost45hq",
                      align: "center",
                      title: "45HQ",
                      width: "70"
                    }
                  })
                ],
                1
              ),
              _c(
                "vxe-colgroup",
                { attrs: { title: "标准价", align: "center" } },
                [
                  _c("vxe-column", {
                    attrs: {
                      field: "standard20gp",
                      align: "center",
                      title: "20GP",
                      width: "70"
                    }
                  }),
                  _c("vxe-column", {
                    attrs: {
                      field: "standard40gp",
                      align: "center",
                      title: "40GP",
                      width: "70"
                    }
                  }),
                  _c("vxe-column", {
                    attrs: {
                      field: "standard40hq",
                      align: "center",
                      title: "40HQ",
                      width: "70"
                    }
                  }),
                  _c("vxe-column", {
                    attrs: {
                      field: "standard45hq",
                      align: "center",
                      title: "45HQ",
                      width: "70"
                    }
                  })
                ],
                1
              ),
              _c("vxe-column", {
                attrs: {
                  title: "附加费",
                  width: "350",
                  "show-overflow-tooltip": "",
                  align: "center",
                  field: "surcharge"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  title: "备注",
                  width: "350",
                  "show-overflow-tooltip": "",
                  align: "center",
                  field: "remark"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "ageing",
                  title: "时效",
                  "show-overflow-tooltip": "",
                  width: "90"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "includingTaxes",
                  title: "是否含税",
                  "show-overflow-tooltip": "",
                  width: "90"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  title: "生效日",
                  align: "center",
                  field: "effectiveDate",
                  width: "110"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  title: "失效日",
                  align: "center",
                  field: "expiringDate",
                  width: "110"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  title: "创建人",
                  align: "center",
                  field: "createBy",
                  width: "110"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  title: "创建时间",
                  align: "center",
                  field: "createTime",
                  width: "110"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  title: "修改人",
                  align: "center",
                  field: "updateBy",
                  width: "110"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  title: "修改时间",
                  align: "center",
                  field: "updateTime",
                  width: "110"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  title: "编号",
                  align: "center",
                  field: "code",
                  width: "110"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  title: "产品专员",
                  align: "center",
                  field: "productCommissioner",
                  fixed: "right",
                  width: "100"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  title: "状态",
                  fixed: "right",
                  align: "center",
                  field: "status",
                  width: "80"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.status == "待提交"
                          ? _c(
                              "span",
                              [
                                _c("el-tag", [_vm._v(_vm._s(scope.row.status))])
                              ],
                              1
                            )
                          : _vm._e(),
                        scope.row.status == "已提交"
                          ? _c(
                              "span",
                              [
                                _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v(_vm._s(scope.row.status))
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        scope.row.status == "失效"
                          ? _c(
                              "span",
                              [
                                _c("el-tag", { attrs: { type: "info" } }, [
                                  _vm._v(_vm._s(scope.row.status))
                                ])
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.form.pageNum,
          layout: _vm.layout,
          "page-size": _vm.form.pageSize,
          total: _vm.total,
          "page-sizes": [10, 50, 100, 500, 1000]
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }